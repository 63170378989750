import './style.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import {Map, View} from 'ol';
//import TileLayer from 'ol/layer/Tile';
import {Image as ImageLayer, Tile as TileLayer} from 'ol/layer.js';
import TileWMS from 'ol/source/TileWMS.js';
import ImageWMS from 'ol/source/ImageWMS.js';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import GeoJSON from 'ol/format/GeoJSON';
import {Icon, Style, Fill, Stroke} from 'ol/style';
// import sync from 'ol-hashed'; // need to import if using sync
import { fromLonLat } from 'ol/proj';
import {Control, ZoomToExtent, defaults as defaultControls} from 'ol/control';
import {Vector as VectorSource} from 'ol/source';
import LayerGroup from 'ol/layer/Group';
import LayerSwitcher from 'ol-layerswitcher';
import BingMaps from 'ol/source/BingMaps.js';
import * as olExtent from 'ol/extent';
import {getCenter} from 'ol/extent';
import {
  BROWSER_INPUT_ELEMENT_ID,
  BROWSER_SUGGESTIONS_ELEMENT_ID,
  BROWSER_SUGGESTIONS_MAX_SIZE,
} from './config.mjs';
import { AppDropdownElement } from './dropdown-element.mjs';
import { fuzzySearch } from './fuzzy-search.mjs';
import Attribution from 'ol/control/Attribution.js';
import { preventDefault } from 'ol/events/Event';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom.js';
import Interaction from 'ol/interaction/Interaction.js';
import {defaults} from 'ol/interaction/defaults';

// function to get inner window height for mobile browsers and set body height to it to prevent scrolling, or hidden content behind the bottom nav bar
window.onresize = function() {
  document.body.height = window.innerHeight;
}
window.onresize(); // called to initially set the height.

let initialViewportWidth = window.innerWidth;
//console.log(initialViewportWidth);
const panel_close = "https://lcic.ca/wp-content/uploads/2023/02/chevron-left-icon-lcic-blu.png";
const panel_open = "https://lcic.ca/wp-content/uploads/2023/02/chevron-right-icon-lcic-blu.png";

const geoServerDomain = 'https://geoserver.marktrueman.ca/geoserver/'
const nameSpace = 'LCICLandInventory'
const service = 'WFS'
const version = '2.0.0'
const request = 'GetFeature'
const layerName = 'Development Potential'
const layerName2 = 'LCICLandInv_select_Ind_Com_clean'
const typeName = `${nameSpace}%3A${layerName}`
const typeName2 = `${nameSpace}%3A${layerName2}`
const count = 1500

const serviceWMS = 'WMS'
const versionWMS = '1.1.1'
const requestWMS = 'GetMap'
const layerNameWMS = 'MS Buildings'
const typeNameWMS = `${nameSpace}%3A${layerNameWMS}`

const buildingExtent4 = fromLonLat([-118.13625242999996, 49.00027171000005])
const buildingExtent5 = fromLonLat([-117.46512500499995, 49.26523877900007])
const buildingExtent6 = buildingExtent4.concat(buildingExtent5);
console.log(buildingExtent6)

/* ****Layer URLs **** */
const featureLayerWFS = `${geoServerDomain}${nameSpace}/ows?service=${service}&version=${version}&request=${request}&typeName=${typeName}&count=${count}&outputFormat=application%2Fjson`
const featureLayerWFS2 = `${geoServerDomain}${nameSpace}/ows?service=${service}&version=${version}&request=${request}&typeName=${typeName2}&count=${count}&outputFormat=application%2Fjson`
const buildingLayerURL = "https://158.69.112.197/geoserver/LCICLandInventory/wms?service=WMS&version=1.1.0&request=GetMap&layers=LCICLandInventory%3AMS%20Buildings&bbox=-117.96678161621094%2C48.999595642089844%2C-117.48191833496094%2C49.20425796508789&width=768&height=330&srs=EPSG%3A3857&styles=&format=application/openlayers"


/* ****Region center Coords **** */
const initialView = [-13004708.81, 6652457.134]
const trailandarea = fromLonLat([-117.66993534984215, 49.100971359691975])
const initialCenter = fromLonLat([-117.69903534984215, 49.140971359691975])
const britishColumbia = [-13944609.48, 7355303.448]
const canada = fromLonLat([-95.444, 57.62])
const initialExtent = [ -13413442.63240057, 633305.097799245, -12853493.531286297, 6504847.982355047 ]

/* **** data **** */
const spinnerContainer = document.querySelector('.spinner-container');
spinnerContainer.style.display = 'block';

/* Inventory data */
async function fetchData() {
  spinnerContainer.style.display = 'block';
  try {
    const [geojsonObjWFS2, geojsonObjWFS] = await Promise.all([
      fetch(featureLayerWFS2).then(res => res.json()),
      fetch(featureLayerWFS).then(res => res.json())
    ]);
    // cast property "pid" to number
    geojsonObjWFS.features.forEach((feature) => {
      feature.properties.pid = Number(feature.properties.pid)
    })
    geojsonObjWFS2.features.forEach((feature) => {
      feature.properties.pid = Number(feature.properties.pid)
    })
    console.log('fetch complete')
    
    return { /*jsonObj, geoJSONPointArr, */geojsonObjWFS2, geojsonObjWFS };
  } catch (error) {
    console.log('Error fetching data:', error);
    location.reload();
  }
}

const { /*jsonObj, geoJSONPointArr,*/ geojsonObjWFS2, geojsonObjWFS } = await fetchData();
console.log(geojsonObjWFS)

// replace spinner with map when data fetched
spinnerContainer.style.display = 'none';
const outer_wrapper = document.getElementById('outer-wrapper');
outer_wrapper.style.display = 'flex';

window.addEventListener('DOMContentLoaded', function() {  
  //console.log(initialViewportWidth)
  if (initialViewportWidth <= 625 && initialViewportWidth > 480) {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.add("no-list");
  } else {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.remove("no-list");
  }
 
  if (initialViewportWidth <= 480) {
    let stringValue = document.getElementById("string-value");
    stringValue.multiple = false;
    let legend = document.getElementById("legend-wrapper");
    legend.style.display = "none";
  } else {
    let stringValue = document.getElementById("string-value");
    stringValue.multiple = true;
    let legend = document.getElementById("legend-wrapper");
    legend.style.display = "block";
  }
});


/* ************map Variables************* */

/* *** VectorSources *** */
const vectorSourceWFS = new VectorSource({
  features: new GeoJSON().readFeatures(geojsonObjWFS)
});
const vectorSourceWFS2 = new VectorSource({
  features: new GeoJSON().readFeatures(geojsonObjWFS2)
});

/* *** WMS Sources *** */
const tileSourceWMS_lcic_aoi = new TileWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:LCIC_LandInv_AOI`,
    'TILED': true,
    'STYLES': 'lcic_blu_stroke_poly',
  },
  serverType: 'geoserver',
  transition: 0,
})

const imageSourceWMS_lcic_cenSub = new ImageWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:census_sub_div_landInv`,
    'TILED': true,
    'STYLES': 'cenSubDivLCIC_grn-pur',
  },
  serverType: 'geoserver',
  transition: 0,
})

const tileSourceWMS_msBuildings = new TileWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:MS Buildings`,
    'TILED': true,
    'STYLES': 'red_poly_stroke',
  },
  serverType: 'geoserver',
  transition: 0,
})
const tileSourceWMS_connectivity = new TileWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:LCIC_Connectivity_50mBuffer`,
    'TILED': true,
    'STYLES': '',
  },
  serverType: 'geoserver',
  transition: 0,
})
const tileSourceWMS_enviroRem = new TileWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:LCIC_EnvRmdtn_select`,
    'TILED': true,
    'STYLES': '',
  },
  serverType: 'geoserver',
  transition: 0,
})
const tileSourceWMS_electricService = new TileWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:LCIC_AOI_Fortis_Electric_service_area_50m`,
    'TILED': true,
    'STYLES': '',
  },
  serverType: 'geoserver',
  transition: 0,
})
const tileSourceWMS_gasService = new TileWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:LCIC_AOI_Fortis_Gas_service_area_50m`,
    'TILED': true,
    'STYLES': '',
  },
  serverType: 'geoserver',
  transition: 0,
})
const tileSourceWMS_RDKBSewer = new TileWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:RDKB sewer service area 50m`,
    'TILED': true,
    'STYLES': '',
  },
  serverType: 'geoserver',
  transition: 0,
})
const tileSourceWMS_RDKBWater = new TileWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:RDKB water service area 50m`,
    'TILED': true,
    'STYLES': '',
  },
  serverType: 'geoserver',
  transition: 0,
})
const tileSourceWMS_trailWater = new TileWMS({
  url: `${geoServerDomain}/wms`,
  extent: buildingExtent6,
  params: {
    'LAYERS': `${nameSpace}:Trail water service area 50m`,
    'TILED': true,
    'STYLES': '',
  },
  serverType: 'geoserver',
  transition: 0,
})


/* *** VectorLayers *** */
/* *** Base Map Layers *** */
const OSMbaseMap = new TileLayer({
  title: 'Open Street Maps',
  //type: 'base',
  visible: true,
  source: new OSM(),
});

const BingArielBase = new TileLayer({
  title: 'Bing Ariel Image',
  //type: 'base',
  visible: true,
  source: new BingMaps({
    key: 'Aq3zTnJcwqxgsr49ctWDJQbSaZsmhkdIrDuFMBdoCXZTBE31Gl-nam7XhVkbHHy9',
    imagerySet: 'Aerial',
    maxZoom: 19, // add maxZoom option to the BingMaps source
    attributions: ''
  })
});


/* *** Admin Layers *** */
const lcicAoiLayerWMS = new TileLayer({
  title: 'LCIC Area of Interest',
  visible: true,
  source: tileSourceWMS_lcic_aoi,
});

const censusSubLayerWMS = new ImageLayer({
  title: 'Census Subdivisions',
  visible: false,
  source: imageSourceWMS_lcic_cenSub,
  opacity: 0.8,
});

/* *** Inventory Layers *** */
const landInvLayerWFS = new VectorLayer({
  title: 'Land Inventory Development Potential (Basic)',
  style: styleFunction_9, 
  visible: true,
  opacity: 0.8,
  source: vectorSourceWFS,
  type: "base",
});
const landInvDevelopmentWeighted = new VectorLayer({
  title: 'Land Inventory Development Potential (Weighted)',
  style: styleFunction_12, 
  visible: false,
  opacity: 0.8,
  source: vectorSourceWFS,
  type: "base",
});
const landInvSelect = new VectorLayer({
  title: 'Land Inventory Industrial/Commercial Select (Basic)',
  style: styleFunction_12, 
  visible: false,
  opacity: 0.8,
  source: vectorSourceWFS2,
  type: "base",
});
const landInvServiceWeighted = new VectorLayer({
  title: 'Land Inventory Development Potential (Services Weighted)',
  style: styleFunction_10, 
  visible: false,
  opacity: 0.8,
  source: vectorSourceWFS,
  type: "base",
});
const landInvSlopeWeighted = new VectorLayer({
  title: 'Land Inventory Development Potential (Slope Weighted)',
  style: styleFunction_13, 
  visible: false,
  opacity: 0.8,
  source: vectorSourceWFS,
  type: "base",
});
const landInvCurrentUse = new VectorLayer({
  title: 'Land Inventory Current Use',
  style: styleFunction_5a, 
  visible: false,
  opacity: 0.8,
  source: vectorSourceWFS,
  type: "base",
});
const landInvServices = new VectorLayer({
  title: 'Land Inventory Services',
  style: styleFunction_6, 
  visible: false,
  opacity: 0.8,
  source: vectorSourceWFS,
  type: "base",
});
const landInvSlope = new VectorLayer({
  title: 'Land Inventory Average Slope',
  style: styleFunction_5, 
  visible: false,
  opacity: 0.8,
  source: vectorSourceWFS,
  type: "base",
});
const landInvZonePriority = new VectorLayer({
  title: 'Land Inventory Zoning Priority',
  style: styleFunction_3, 
  visible: false,
  opacity: 0.8,
  source: vectorSourceWFS,
  type: "base",
});

/* *** Asset Layers *** */
const buildingLayerWMS = new TileLayer({
  title: 'Buildings',
  visible: false,
  source: tileSourceWMS_msBuildings
});
const connectivityLayerWMS = new TileLayer({
  title: 'Connectivity 50m buffer',
  visible: false,
  source: tileSourceWMS_connectivity,
  opacity: 0.5
});
const enviroRemLayerWMS = new TileLayer({
  title: 'Environmental Remediation Sites',
  visible: false,
  source: tileSourceWMS_enviroRem,
  opacity: 0.5
});
const electricServiceLayerWMS = new TileLayer({
  title: 'Electrical Service Area 50m',
  visible: false,
  source: tileSourceWMS_electricService,
  opacity: 0.5
});
const gasServiceLayerWMS = new TileLayer({
  title: 'Gas Service Area 50m',
  visible: false,
  source: tileSourceWMS_gasService,
  opacity: 0.5
});
const RDKBSewerLayerWMS = new TileLayer({
  title: 'RDKB Sewer Service Area 50m',
  visible: false,
  source: tileSourceWMS_RDKBSewer,
  opacity: 0.5
});
const RDKBWaterLayerWMS = new TileLayer({
  title: 'RDKB Water Service Area 50m',
  visible: false,
  source: tileSourceWMS_RDKBWater,
  opacity: 0.5
});
const trailWaterLayerWMS = new TileLayer({
  title: 'Trail Water Service Area 50m',
  visible: false,
  source: tileSourceWMS_trailWater,
  opacity: 0.5
});

const view = new View({
  center: initialCenter, 
  zoom: 11, 
  minZoom: 2,
  maxZoom: 20,
  crs: 'EPSG:3857',
  constrainResolution: false,
});

const baseMaps = new LayerGroup({
  title: 'Base maps',
  layers: [BingArielBase, OSMbaseMap, /*BingBaseLight*/]
})
let adminLayers = new LayerGroup({
  title: 'Administrative Layers',
  layers: [censusSubLayerWMS, lcicAoiLayerWMS],
  fold: 'open',
  visible: true,
})
let inventoryLayers = new LayerGroup({
  title: 'Land Inventory',
  layers: [landInvZonePriority, landInvSlope, landInvServices, landInvCurrentUse, landInvSlopeWeighted,landInvServiceWeighted, landInvDevelopmentWeighted, landInvSelect, landInvLayerWFS],
  fold: 'open',
  visible: true,
})
let filteredLayers = new LayerGroup({
  title: 'Filtered Layers',
  layers: [],
  fold: 'open',
  visible: false,
})
let assetLayers = new LayerGroup({
  title: 'Raw Asset Layers',
  layers: [/*RDKBSewerLayerWMS, RDKBWaterLayerWMS, trailWaterLayerWMS, electricServiceLayerWMS, gasServiceLayerWMS, connectivityLayerWMS,*/ buildingLayerWMS, enviroRemLayerWMS],
  fold: 'close',
  visible: true,
})

const attributionControl = new Attribution({
  collapsed: true, // set the attribution control to be collapsed by default
  collapsible: true // allow the attribution control to be collapsed/expanded by the user
});


const map = new Map({
  target: 'map',
  layers: [baseMaps, adminLayers, inventoryLayers, filteredLayers, assetLayers],
  controls: defaultControls({
    attribution: false // disable the default attribution control
  }).extend([attributionControl]),
  view: view,
  interactions: defaults({
    doubleClickZoom: false
  }),
});



/* ******** Layer switcher functions******** */
let layer_switcher_container = document.getElementById("layerSwitcherContainer")

let layerSwitcher = new LayerSwitcher({
  reverse: true,
  startActive: layerSwitcherMediaSize(),
  collapseLabel: '',
  tipLabel: 'Layer Switcher', // Optional label for button
  groupSelectStyle: 'children', // Can be 'children' [default], 'group' or 'none'
  activationMode: 'click', // Can be 'click' [default], 'hover' or 'none'
  });

map.addControl(layerSwitcher);
let layer_switcher_button = document.querySelector('button[title="Collapse legend"]');
let layer_switcher_button_768 = document.querySelector('button[title="Layer Switcher"]');

if (layer_switcher_button) {
  layer_switcher_container.appendChild(layer_switcher_button);
} else {
  layer_switcher_container.appendChild(layer_switcher_button_768);
}
/* ******** End Layer Switcher functions ******** */


/* ************** Screen size Conditions *************** */
// Add event listener to check if screen size has changed
window.addEventListener('resize', function() {  
  if (layerSwitcherMediaSize()) {
    layerSwitcher.showPanel();
  } else {
    layerSwitcher.hidePanel();
  } 
  let viewportWidth = window.innerWidth;
  //console.log(viewportWidth)
  if (viewportWidth <= 625 && viewportWidth > 480) {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.add("no-list");
  } else {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.remove("no-list");
  }
  //console.log(viewportWidth)
  if (viewportWidth <= 625 && viewportWidth > 480) {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.add("no-list");
  } else {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.remove("no-list");
  }
 
  if (viewportWidth <= 480) {
    let stringValue = document.getElementById("string-value");
    stringValue.multiple = false;
    let legend = document.getElementById("legend-wrapper");
    legend.style.display = "none";
  } else {
    let stringValue = document.getElementById("string-value");
    stringValue.multiple = true;
    let legend = document.getElementById("legend-wrapper");
    legend.style.display = "block";
  }
});

function layerSwitcherMediaSize() {
  const viewportWidth = window.innerWidth;
  if (viewportWidth <= 768) {
    return false;
  }
  return true;
}


/* ***************end screen size conditions *************** */

// sync(map); need to import ol-hashed if using

/* ********************BEGIN POPUPS********************* */
let landInvLayer_select;
var container = document.getElementById('popup'),
    content_element = document.getElementById('popup-content'),
    closer = document.getElementById('popup-closer');

closer.onclick = function() {
    overlay.setPosition(undefined);
    map.removeLayer(landInvLayer_select);
    closer.blur();
    return false;
};
var overlay = new Overlay({
    element: container,
    autoPan: true,
    offset: [0, -10]
});

map.addOverlay(overlay);

// function to display popup content
map.on('click', function(evt){
  var feature = map.forEachFeatureAtPixel(evt.pixel,
    function(feature) {
      return feature;
    });
    if (feature) {
      let geometry = feature.getGeometry();
      let geometryType = geometry.getType();

      if (geometryType === 'Point'){
        pointPopupContent(feature);
      } else if (geometryType === 'MultiPolygon') {     
        multipolyPopupContent(feature);
        map.removeLayer(landInvLayer_select);
        //Select Feature Layer
        let selectFeature = feature;
        landInvLayer_select = new VectorLayer({
          //title: 'Select',
          style: styleSelect,
          visible: true,
          opacity: 1,
          source: new VectorSource({
            features: [selectFeature]
          }),
        });      
        map.addLayer(landInvLayer_select);
     
      }
    }
});

/* ***************BEGIN POPUP CONTENT FUNCTIONS**************** */

// Function for creating content for point feature
function pointPopupContent(feature) { 
  let geometry = feature.getGeometry();
  let point_coord = geometry.getCoordinates();
  let content = `
    <h2> ${feature.get('label')} </h2>
    <h5 id=popup-category >CATEGORY - ${feature.get('category')}</h5>
    <h5 id=popup-website ><a href= ${feature.get('website')}> ${feature.get('label')}</a></h5>
    <h5 id=popup-email><a href=mailto: ${feature.get('email')}> ${feature.get('email')}</a></h5>
    <h5 id=popup-phone><a href=tel: ${feature.get('phone')}>${feature.get('phone')}</a></h5>
    <h5 id=popup-address>${feature.get('address')}</h5>
    <hr class=rounded >
    <h5 id=popup-description>${feature.get('description')}</h5>
  `;
  content_element.innerHTML = content;
  overlay.setPosition(point_coord);
}

// Function for creating content for multipolygon feature
function multipolyPopupContent(feature) {
  let geometry = feature.getGeometry();
  let extent = geometry.getExtent()
  let poly_coord = olExtent.getCenter(extent);
  let area_acres = feature.get('area_acres');
  let rounded_area_acres = area_acres.toFixed(4);
  let avg_slope = feature.get('avg_slope');
  let rounded_avg_slope = avg_slope.toFixed(2);
  let activeLayer = inventoryLayers.getLayers().getArray().filter(function(layer) {
    return layer.getVisible();
    })[0];
  let activeLayerTitle = activeLayer.get('title');
  let activeAttribute = '';
  if (activeLayerTitle === 'Land Inventory Development Potential (Weighted)') {
    activeAttribute = 'development_score_weighted';
  } else if (activeLayerTitle === 'Land Inventory Development Potential (Services Weighted)') {
    activeAttribute = 'development_score_service';
  } else if (activeLayerTitle === 'Land Inventory Development Potential (Slope Weighted)') {
    activeAttribute = 'development_score_slope_weighted';
  } else if (activeLayerTitle === 'Land Inventory Zoning Priority') {
    activeAttribute = 'zone_priority';  
  } else if (activeLayerTitle === 'Land Inventory Services') {
    activeAttribute = 'services_score_sum';  
  } else {
    activeAttribute = 'development_score_basic';
  }
  let activeLayerString = (activeLayerTitle === 'Land Inventory Development Potential (Basic)' || 
                           activeLayerTitle === 'Land Inventory Industrial/Commercial Select (Basic)' ||
                           activeLayerTitle === 'Land Inventory Current Use' ||
                           activeLayerTitle === 'Land Inventory Average Slope')
                          ? 'Land Inventory Development Potential (Basic)'
                          : activeLayerTitle;

  let content = `
    <h2 id= popup-pid class= landInv>PID - ${feature.get('pid')}</h2>
    <h5 id=popup-shape-area class= landInv>AREA (acres) - ${rounded_area_acres}</h5>
    <h5 id=popup-zone-name class= landInv>ZONE - ${feature.get('zone_name')}</h5>
    <h5 id=popup-zone-admin class= landInv>ZONE ADMINISTRATION - ${feature.get('zone_admin')}</h5>
    <h5 id=popup-current-use class= landInv>CURRENT USAGE - ${feature.get('current_use')}</h5>
    <h5 id=popup-services class= landInv>SERVICES - ${feature.get('services')}</h5>
    <h5 id=popup-avg-slope class= landInv>AVERAGE SLOPE - ${rounded_avg_slope}</h5>
    <h5 id=popup-development-score class= landInv>${activeLayerString.toUpperCase().split(" ").slice(2).join(" ")} - ${feature.get(activeAttribute)}</h5>  
  `;
  content_element.innerHTML = content;
  overlay.setPosition(poly_coord);

  //console.info(feature.getProperties());
}
/* ***************END POPUP CONTENT FUNCTIONS**************** */
/* ********************END POPUPS********************* */

/* ***************BEGIN STYLE FUNCTIONS**************** */

// Style for land inventory layer

const fillColors_13 = [
  {
      "value": 8,
      "color": '165,0,38',
  },
  {
      "value": 9,
      "color": '215, 25, 28',
  },
  {
      "value": 10,
      "color": '229, 79, 53'
  },
  {
      "value": 11,
      "color": '243, 133, 78'
  },
  {
      "value": 12,
      "color": '253, 181, 106'
  },
  {
      "value": 13,
      "color": '254, 211, 140'
  },
  {
      "value": 14,
      "color": '255, 240, 175'
  },
  {
      "value": 15,
      "color": '239, 248, 176'
  },
  {
      "value": 16,
      "color": '206, 234, 145'
  },
  {
      "value": 17,
      "color": '174, 220, 114'
  },
  {
      "value": 18,
      "color": '128, 199, 95'
  },
  {
      "value": 19,
      "color": '77, 174, 80'
  },
  {
      "value": 20,
      "color": '26, 150, 65'
  }
]
const fillColors_12 = [
  {
      "value": 8,
      "color": '215, 25, 28',
  },
  {
      "value": 9,
      "color": '229, 79, 53'
  },
  {
      "value": 10,
      "color": '243, 133, 78'
  },
  {
      "value": 11,
      "color": '253, 181, 106'
  },
  {
      "value": 12,
      "color": '254, 211, 140'
  },
  {
      "value": 13,
      "color": '255, 240, 175'
  },
  {
      "value": 14,
      "color": '239, 248, 176'
  },
  {
      "value": 15,
      "color": '206, 234, 145'
  },
  {
      "value": 16,
      "color": '174, 220, 114'
  },
  {
      "value": 17,
      "color": '128, 199, 95'
  },
  {
      "value": 18,
      "color": '77, 174, 80'
  },
  {
      "value": 19,
      "color": '26, 150, 65'
  }
]
const fillColors_10 = [
  {
      "value": 7,
      "color": '165,0,38',
  },
  {
      "value": 8,
      "color": '215,48,39',
  },
  {
      "value": 9,
      "color": '244,109,67'
  },
  {
      "value": 10,
      "color": '253,174,97'
  },
  {
      "value": 11,
      "color": '254,224,139'
  },
  {
      "value": 12,
      "color": '217,239,139'
  },
  {
      "value": 13,
      "color": '166,217,106'
  },
  {
      "value": 14,
      "color": '102,189,99'
  },
  {
      "value": 15,
      "color": '26,152,80'
  },
  {
      "value": 16,
      "color": '0,104,55'
  }
]
const fillColors_9 = [
  {
      "value": 7,
      "color": '215,48,39',
  },
  {
      "value": 8,
      "color": '244,109,67',
  },
  {
      "value": 9,
      "color": '253,174,97'
  },
  {
      "value": 10,
      "color": '254,224,139'
  },
  {
      "value": 11,
      "color": '255,255,191'
  },
  {
      "value": 12,
      "color": '217,239,139'
  },
  {
      "value": 13,
      "color": '166,217,106'
  },
  {
      "value": 14,
      "color": '102,189,99'
  },
  {
      "value": 15,
      "color": '26,152,80'
  }
]
const fillColors_6 = [
  {
      "value": 0,
      "color": '215, 48, 39',
  },
  {
      "value": 1,
      "color": '252, 141, 89'
  },
  {
      "value": 2,
      "color": '254, 224, 139'
  },
  {
      "value": 3,
      "color": '217, 239, 139'
  },
  {
      "value": 4,
      "color": '145, 207, 96'
  },
  {
      "value": 5,
      "color": '26, 152, 80'
  }
]
const fillColors_5 = [
  {
      "value": 0,
      "color": '215, 25, 28',
  },
  {
      "value": 1,
      "color": '253,174,97'
  },
  {
      "value": 2,
      "color": '255,255,191'
  },
  {
      "value": 3,
      "color": '166,217,106'
  },
  {
      "value": 4,
      "color": '26,150,65'
  }
]
const fillColors_3 = [
  {
      "value": 1,
      "color": '252,141,89'
  },
  {
      "value": 2,
      "color": '255,255,191'
  },
  {
      "value": 3,
      "color": '145,207,96'
  }
]


function styleFunction_13 (feature) {
  let symbolValue = feature.get('development_score_slope_weighted');
  
  const stroke_blk = new Stroke({
    color: 'black',
    width: 0.4,
  })

  let color;
  for (let i = 0; i < fillColors_13.length; i++) {
    if (fillColors_13[i].value === symbolValue) {
      color = `rgb(${fillColors_13[i].color})`;
      break;
    }
  }

  let style = new Style({
    stroke: stroke_blk,
    fill: new Fill({
      color: color,
    }),
  });
  return [style];
  };
function styleFunction_12 (feature) {
  let symbolValue = feature.get('development_score_weighted');
  
  const stroke_blk = new Stroke({
    color: 'black',
    width: 0.4,
  })

  let color;
  for (let i = 0; i < fillColors_12.length; i++) {
    if (fillColors_12[i].value === symbolValue) {
      color = `rgb(${fillColors_12[i].color})`;
      break;
    }
  }

  let style = new Style({
    stroke: stroke_blk,
    fill: new Fill({
      color: color,
    }),
  });
  return [style];
  };
function styleFunction_10 (feature) {
  let symbolValue = feature.get('development_score_service');
  
  const stroke_blk = new Stroke({
    color: 'black',
    width: 0.4,
  })

  let color;
  for (let i = 0; i < fillColors_10.length; i++) {
    if (fillColors_10[i].value === symbolValue) {
      color = `rgb(${fillColors_10[i].color})`;
      break;
    }
  }

  let style = new Style({
    stroke: stroke_blk,
    fill: new Fill({
      color: color,
    }),
  });
  return [style];
  };

function styleFunction_9 (feature) {
  let symbolValue = feature.get('development_score_basic');
  
  const stroke_blk = new Stroke({
    color: 'black',
    width: 0.4,
  })

  let color;
  for (let i = 0; i < fillColors_9.length; i++) {
    if (fillColors_9[i].value === symbolValue) {
      color = `rgb(${fillColors_9[i].color})`;
      break;
    }
  }

  let style = new Style({
    stroke: stroke_blk,
    fill: new Fill({
      color: color,
    }),
  });
  return [style];
  };
function styleFunction_6 (feature) {
  let symbolValue = feature.get('services_score_sum');
  
  const stroke_blk = new Stroke({
    color: 'black',
    width: 0.4,
  })

  let color;
  for (let i = 0; i < fillColors_6.length; i++) {
    if (fillColors_6[i].value === symbolValue) {
      color = `rgb(${fillColors_6[i].color})`;
      break;
    }
  }

  let style = new Style({
    stroke: stroke_blk,
    fill: new Fill({
      color: color,
    }),
  });
  return [style];
  };
function styleFunction_5 (feature) {
  let symbolValue = feature.get('avg_slope_score');
  
  const stroke_blk = new Stroke({
    color: 'black',
    width: 0.4,
  })

  let color;
  for (let i = 0; i < fillColors_5.length; i++) {
    if (fillColors_5[i].value === symbolValue) {
      color = `rgb(${fillColors_5[i].color})`;
      break;
    }
  }

  let style = new Style({
    stroke: stroke_blk,
    fill: new Fill({
      color: color,
    }),
  });
  return [style];
  };
function styleFunction_5a (feature) {
  let symbolValue = feature.get('current_use');
  const stroke_blk = new Stroke({
    color: 'black',
    width: 0.4,
  })

  let color;
  for (let i = 0; i < fillColors_5.length; i++) {
    if (fillColors_5[i].value === symbolValue) {
      color = `rgb(${fillColors_5[i].color})`;
      break;
    }
  }

  let style = new Style({
    stroke: stroke_blk,
    fill: new Fill({
      color: color,
    }),
  });
  return [style];
  };
  
function styleFunction_3 (feature) {
  let symbolValue = feature.get('zone_priority');
  
  const stroke_blk = new Stroke({
    color: 'black',
    width: 0.4,
  })

  let color;
  for (let i = 0; i < fillColors_3.length; i++) {
    if (fillColors_3[i].value === symbolValue) {
      color = `rgb(${fillColors_3[i].color})`;
      break;
    }
  }

  let style = new Style({
    stroke: stroke_blk,
    fill: new Fill({
      color: color,
    }),
  });
  return [style];
  };

function styleSelect () {  
  const stroke_blu = new Stroke({
    color: 'blue',
    width: 2,
  });
  let style = new Style({
    stroke: stroke_blu,
  });
  return [style];
  };

/* ***************END STYLE FUNCTIONS**************** */

/* ****DOM element variables**** */
let List = document.getElementById('list');
let FeatureList = document.getElementById("feature-list");
let list_toggle_img = document.querySelector('#list-toggle > img');
let featureCloser = document.getElementById('feature-closer');
let wrapper = document.getElementById('wrapper');
let current_view_values = []; // holding variable for current mapview

/* ****event listeners**** */
featureCloser.addEventListener('click', clearFeature);
list_toggle_img.addEventListener('click', hideList);

/* ***Hyperlinks*** */
const devPotFactHyperlinks = document.querySelectorAll('.dev-pot-fact-hyperlink');
const devPotScoreHyperlinks = document.querySelectorAll('.dev-pot-score-hyperlink');
const devPotZoneHyperlinks = document.querySelectorAll('.dev-pot-zone-hyperlink');

function expandSection(contentId) {
  const content = document.getElementById(contentId);
  const arrow = document.querySelector(`[data-content-id="${contentId}"]`);

  if (content && arrow) {
    content.classList.remove('hidden-content');
    arrow.src = 'https://trugis.ca/wp-content/uploads/2023/04/opened_drkBlu.png';
  }
}

function addExpandListeners(hyperlinks) {
  Array.from(hyperlinks).forEach(link => {
    link.addEventListener('click', () => {
      const contentId = link.dataset.contentId;
      expandSection(contentId);
    });
  });
}

addExpandListeners(devPotFactHyperlinks);
addExpandListeners(devPotScoreHyperlinks);
addExpandListeners(devPotZoneHyperlinks);

/* ******************BEGIN FILTER FUNCTIONS***************** */

// Add more filter attributes
const addAttributeBtn = document.getElementById("add-attribute-btn");
const filterSubmitBtn = document.getElementById("filter-submit-btn");
const filterClearBtn = document.getElementById("filter-clear-btn");
const currentFilters = document.getElementById("current-filters");

addAttributeBtn.addEventListener("click", addAttribute);

let propertyCounter = 1;

function addAttribute() {
  filterSubmitBtn.classList.remove("hidden");
  filterClearBtn.classList.remove("hidden");

  const filter = document.getElementById("filter");
  const filterBtnsContainer = document.getElementById("filter-btns-container");

  let form = document.createElement("div");
  let rangeInput2 = document.createElement("div");
  let label = document.createElement("label");
  let propertySelect = document.createElement("select");
  let option = document.createElement("option");
  let br = document.createElement("br");
  let stringLabel = document.createElement("label");
  let stringSelect = document.createElement("select");
  let numLowLabel = document.createElement("label");
  let numLowInput = document.createElement("input");
  let numHighLabel = document.createElement("label");
  let numHighInput = document.createElement("input");

  form.setAttribute("id", "form" + propertyCounter);
  form.setAttribute("class", "filter-form");

  label.setAttribute("for", "property" + propertyCounter);
  label.innerHTML = "Select Attribute " + propertyCounter;

  propertySelect.setAttribute("id", "property" + propertyCounter);
  propertySelect.setAttribute("name", "property" + propertyCounter);
  propertySelect.setAttribute("class", "property, property-select");

  option.setAttribute("value", "");
  option.innerHTML = "Select Attribute " + propertyCounter;

  stringLabel.setAttribute("for", "string-value" + propertyCounter);
  stringLabel.innerHTML = "Select Value(s)";
  stringLabel.setAttribute("id", "string-label" + propertyCounter);
  stringLabel.setAttribute("style", "display: none");
  stringLabel.setAttribute("class", "values");

  stringSelect.setAttribute("id", "string-value" + propertyCounter);
  stringSelect.setAttribute("name", "string-value" + propertyCounter);
  stringSelect.setAttribute("style", "display: none");
  stringSelect.setAttribute("class", "values");
  stringSelect.setAttribute("multiple", "multiple");

  rangeInput2.setAttribute("id", "range-input" + propertyCounter);
  rangeInput2.setAttribute("style", "display: none");
  rangeInput2.setAttribute("class", "values");

  numLowLabel.setAttribute("id", "low-number-label" + propertyCounter);
  numLowLabel.setAttribute("for", "low-number-value" + propertyCounter);
  numLowLabel.innerHTML = "Enter Lower Value";

  numLowInput.setAttribute("id", "low-number-value" + propertyCounter);
  numLowInput.setAttribute("type", "number");
  numLowInput.setAttribute("name", "low-number-value" + propertyCounter);
  numLowInput.setAttribute("class", "numberInput");

  numHighLabel.setAttribute("id", "high-number-label" + propertyCounter);
  numHighLabel.setAttribute("for", "high-number-value" + propertyCounter);
  numHighLabel.innerHTML = "Enter Higher Value";

  numHighInput.setAttribute("id", "high-number-value" + propertyCounter);
  numHighInput.setAttribute("type", "number");
  numHighInput.setAttribute("name", "high-number-value" + propertyCounter);
  numHighInput.setAttribute("class", "numberInput");

  form.appendChild(label);
  form.appendChild(propertySelect);
  propertySelect.appendChild(option);
  form.appendChild(br);
  form.appendChild(stringLabel);
  form.appendChild(stringSelect);
  form.appendChild(br);
  form.appendChild(rangeInput2);
  rangeInput2.appendChild(numLowLabel);
  rangeInput2.appendChild(numLowInput);
  rangeInput2.appendChild(br);
  rangeInput2.appendChild(numHighLabel);
  rangeInput2.appendChild(numHighLabel);
  rangeInput2.appendChild(numHighInput);
  filter.insertBefore(form, addAttributeBtn, filterBtnsContainer);

  //and/or functionality  
  let andOrSelect = document.createElement("select");
  andOrSelect.setAttribute("id", "andOr" + propertyCounter);
  andOrSelect.setAttribute("class", "andOr");
  if (propertyCounter > 0) {
    andOrSelect.style.display = "block";
  } else {
    andOrSelect.style.display = "none";
  }
  form.appendChild(andOrSelect);

  let orOption = document.createElement("option");
  orOption.setAttribute("value", "or");
  orOption.innerHTML = "OR";
  andOrSelect.appendChild(orOption);

  let andOption = document.createElement("option");
  andOption.setAttribute("value", "and");
  andOption.innerHTML = "AND";
  andOrSelect.appendChild(andOption);

  // Populate the new property select element with options and add an event listener
  populatePropertySelect(propertySelect);
  propertySelect.addEventListener('change', handlePropertyChange);

  propertyCounter++;
};

/* ****Land Inventory property filters**** */
//Get the property select and value select elements

let rangeValues = {low: '', high: ''};
let rangeArray;
let stringValues_array;

function populatePropertySelect(propertySelect) {
  // Extract unique properties from GeoJSON object
  let properties = new Set();
  geojsonObjWFS.features.forEach(feature => {
    const filterArray = ['pid', 'area_acres', 'zone_name', 'zone_admin', 'services', 'current_use', 'avg_slope', 'development_score_weighted', 'development_score_slope_weighted', 'development_score_service', 'development_score_basic', 'services_score_sum', 'avg_slope_score', 'zone_priority'];

    Object.keys(feature.properties).forEach(property => {
      if(filterArray.includes(property)) {
        properties.add(property);
      } 
    });
  });

  // Add options to the property select
  properties.forEach(property => {
    const option = document.createElement('option');
    option.value = property;
    option.innerHTML = property;
    propertySelect.appendChild(option);
  });
}

function handlePropertyChange(event) {
  const propertySelect = event.target;
  const selectedProperty = propertySelect.value;
  console.log(propertySelect.id)
  const eleId = propertySelect.id.slice(-1);
  // Clear the user input values for the corresponding property
  const stringValue = document.getElementById(`string-value${eleId}`);
  console.log(stringValue)
  const lowNumberValue = document.getElementById(`low-number-value${eleId}`);
  const highNumberValue = document.getElementById(`high-number-value${eleId}`);
  stringValue.innerHTML = "";
  lowNumberValue.innerHTML = "";
  highNumberValue.innerHTML = "";

  // Extract unique values for selected property from GeoJSON object
  let values = new Set();
  geojsonObjWFS.features.forEach(feature => {
    if(feature.properties[selectedProperty]) 
      values.add(feature.properties[selectedProperty]);
  });

  // Populate the user input values for the corresponding property
  const rangeInput = document.getElementById(`range-input${eleId}`);
  console.log(rangeInput)
  const stringLabel = document.getElementById(`string-label${eleId}`);
  //setElementDisplay(rangeInput, stringLabel, stringValue, !values.has('number'));

  if (values.size > 0) {
    const dataType = typeof [...values][0];
    if (dataType === 'string') {
      [...values].forEach(value => {
        populateStringInput(value, rangeInput, stringLabel, stringValue);
      });
    } else if (dataType === 'number') {
      populateNumberInput([...values], rangeInput, stringLabel, stringValue, lowNumberValue, highNumberValue);
    }
  }
}

function populateStringInput(value, rangeInput, stringLabel, stringValue) {
  setElementDisplay(rangeInput, stringLabel, stringValue, true);

  const option = document.createElement('option');
  option.value = value;
  option.innerHTML = value;
  stringValue.appendChild(option);
}

function populateNumberInput(values, rangeInput, stringLabel, stringValue, lowNumberValue, highNumberValue) {
  setElementDisplay(rangeInput, stringLabel, stringValue, false);

  rangeArray = values;
  const sortedNumbers = [...values].slice().sort((a, b) => a - b);
  const roundedNumbers = sortedNumbers.map((number) => Math.round(number * 10000) / 10000);
  const decimalLength = Number.isInteger(roundedNumbers[0]) ? 0 : roundedNumbers[0].toString().split('.')[1].length;
  const incrementor = 1 / Math.pow(10, decimalLength);

  lowNumberValue.step = incrementor;
  highNumberValue.step = incrementor;
  lowNumberValue.value = roundedNumbers[0];
  highNumberValue.value = roundedNumbers[sortedNumbers.length - 1];
  rangeValues = { low: roundedNumbers[0], high: roundedNumbers[sortedNumbers.length - 1] };

  lowNumberValue.addEventListener('change', () => {
    rangeValues.low = lowNumberValue.valueAsNumber;
  });
  highNumberValue.addEventListener('change', () => {
    rangeValues.high = highNumberValue.valueAsNumber;
  });
}

function setElementDisplay(element1, element2, element3, displayValue) {
  element1.style.display = displayValue ? 'none' : 'block';
  element2.style.display = displayValue ? 'block' : 'none';
  element3.style.display = displayValue ? 'block' : 'none';
}

let geojsonObjWFS_filtered;
let vectorSourceWFS_filtered;
let landInvLayer_filtered;
let andOrValues = [];

function generateFilterTitle(filters, andOrValues) {
  let title = '<span class="filter-label">Filter:</span> ';

  filters.forEach((filter, index) => {
    if (filter.low) {
      title += `<span class="filter-condition">${filter.property} >= ${filter.low} <= ${filter.high}</span>`;
    } else {
      title += `<span class="filter-condition">${filter.property} = ${filter.string}</span>`;
    }

    // Add the user's input for "and/or" if it's not the last element
    if (index < filters.length - 1) {
      title += ` <span class="and-or">${andOrValues[index].toUpperCase()}</span> `;
    }
  });

  return title;
}

function generateCurrentFilter(filters, andOrValues) {
  
  let filterContainer = document.createElement('div');
  filterContainer.classList.add('filter-container');

  filters.forEach((filter, index) => {
    let filterElement = document.createElement('h4');
    filterElement.classList.add('current-filter');
    filterElement.innerHTML = `Attribute ${index + 1}`;
    filterContainer.appendChild(filterElement);

    Object.entries(filter).forEach(([property, value]) => {
      if (property !== 'low' && property !== 'high' && property !== 'string') {
        
        let propertyElement = document.createElement('div');
        propertyElement.classList.add('current-property');
        propertyElement.innerHTML = value;
        filterContainer.appendChild(propertyElement);
      } else {
        if (property === 'string') {

          let valueElement = document.createElement('div');
          valueElement.classList.add('current-value');
          valueElement.innerHTML = value;
          filterContainer.appendChild(valueElement);
        }
      }
    });

    if (filter.low) {
      let value1LabelElement = document.createElement('h5');
      value1LabelElement.classList.add('current-value-label');
      value1LabelElement.innerHTML = `Lower Value:`;
      filterContainer.appendChild(value1LabelElement);

      let value1Element = document.createElement('div');
      value1Element.classList.add('current-number-value');
      value1Element.innerHTML = filter.low;
      filterContainer.appendChild(value1Element);

      let value2LabelElement = document.createElement('h5');
      value2LabelElement.classList.add('current-value-label');
      value2LabelElement.innerHTML = `Higher Value:`;
      filterContainer.appendChild(value2LabelElement);

      let value2Element = document.createElement('div');
      value2Element.classList.add('current-number-value');
      value2Element.innerHTML = filter.high;
      filterContainer.appendChild(value2Element);
    }

    if (index < filters.length - 1) {
      let andOrElement = document.createElement('div');
      andOrElement.classList.add('current-and-or');
      andOrElement.innerHTML = andOrValues[index].toUpperCase();
      filterContainer.appendChild(andOrElement);
    }
  });

  return filterContainer;
}

// Create a new source from the filtered GeoJSON object
filterSubmitBtn.addEventListener("click", function() {
  // Update andOrValues inside the click event listener
  const andOrSelectElements = document.querySelectorAll('.andOr');
  andOrValues = Array.from(andOrSelectElements).map(select => select.value);

  current_view_values = {
    currentCenter: initialCenter,
    currentZoom: 11
  }
  // Define an array to store the objects
  const filters = [];
  const propertySelectElements = document.querySelectorAll('.property-select');

  // Loop through each property select element
  propertySelectElements.forEach((propertySelectElement) => {
    // Get the ID of the corresponding string-value or number inputs
    const propertyId = propertySelectElement.id.replace('property', '');
    const lowNumberId = `low-number-value${propertyId}`;
    const highNumberId = `high-number-value${propertyId}`;
    const stringValueId = `string-value${propertyId}`;

    // Get the value of the property select element
    const propertyValue = propertySelectElement.value;

    // Check if the corresponding inputs exist
    if (document.getElementById(lowNumberId) && document.getElementById(highNumberId)) {
      // If both number inputs exist, create an object with low and high values
      const lowNumberValue = document.getElementById(lowNumberId).value;
      const highNumberValue = document.getElementById(highNumberId).value;
      if (lowNumberValue !== "" && highNumberValue !== "") {
        filters.push({ property: propertyValue, low: lowNumberValue, high: highNumberValue });
      } else if (lowNumberValue !== "") {
        filters.push({ property: propertyValue, low: lowNumberValue });
      } else if (highNumberValue !== "") {
        filters.push({ property: propertyValue, high: highNumberValue });
      } else if (document.getElementById(stringValueId)) {
        // If the string-value input exists, create an object with just that value
        const stringValue = document.getElementById(stringValueId).value;
        filters.push({ property: propertyValue, string: stringValue });
      }
    } else if (document.getElementById(stringValueId)) {
      // If the string-value input exists, create an object with just that value
      const stringValue = document.getElementById(stringValueId).value;
      filters.push({ property: propertyValue, string: stringValue });
    }
  });
  console.log(filters); // Array of objects with property and value(s)

  geojsonObjWFS_filtered = submitFilter(filters);

  // Update filter_title to reflect user input of and/or
  let filter_title = '';
  filter_title = generateFilterTitle(filters, andOrValues);
  console.log(filter_title);

  let filtersElement = generateCurrentFilter(filters, andOrValues);
  let filterHeading = document.createElement('h3');
  filterHeading.innerHTML = 'Recent Filters';
  filterHeading.classList.add('filter-heading');
  currentFilters.appendChild(filterHeading);
  currentFilters.appendChild(filtersElement);

  createFilteredSource(geojsonObjWFS_filtered)
  createFilteredLayer(vectorSourceWFS_filtered, filter_title)
  let extent = vectorSourceWFS_filtered.getExtent()
  //console.log(extent)
  
  addFilterLayer (landInvLayer_filtered, inventoryLayers, extent)  
  filterCloser (landInvLayer_filtered, inventoryLayers)
});

function submitFilter(filters) {
  while (currentFilters.firstChild) {
    currentFilters.removeChild(currentFilters.firstChild);
  }
  let filteredFeatures = geojsonObjWFS.features.filter(feature => {
    let filterResult = false;
    let prevAnd = true;

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      let currentResult;

      if (filter.hasOwnProperty("low") && filter.hasOwnProperty("high")) {
        const propValue = feature.properties[filter.property];
        currentResult = propValue >= filter.low && propValue <= filter.high;
      } else if (filter.hasOwnProperty("string")) {
        currentResult = feature.properties[filter.property] === filter.string;
      }

      if (i > 0) {
        if (andOrValues[i - 1] === "and") {
          prevAnd = prevAnd && currentResult;
        } else {
          prevAnd = prevAnd || currentResult;
        }
      } else {
        prevAnd = currentResult;
      }

      filterResult = prevAnd;
    }

    return filterResult;
  });
 
  console.log(filteredFeatures)

  filterSubmitBtn.classList.add("hidden");
  propertyCounter = 1;
  const elementsToRemove = document.getElementsByClassName("filter-form");
  while (elementsToRemove.length > 0) {
    elementsToRemove[0].remove(); 
  }

  return {
    "type": "FeatureCollection",
    "features": filteredFeatures,
    "crs": {
      "type": "name",
      "properties": { "name": "urn:ogc:def:crs:EPSG::3857" }
    }  
  }  
};

function filterCloser (filteredLayer, layer) {
  
  filterClearBtn.addEventListener("click", function() {
    filterSubmitBtn.classList.add("hidden");
    filterClearBtn.classList.add("hidden");
    propertyCounter = 1;
    const elementsToRemove = document.getElementsByClassName("filter-form");
    while (elementsToRemove.length > 0) {
      elementsToRemove[0].remove(); 
    }
    removeFilterLayer(filteredLayer, layer)
    updateSlider(layers);
    while (currentFilters.firstChild) {
      currentFilters.removeChild(currentFilters.firstChild);
    }
  });
};

function addFilterLayer (filteredLayer, layer, extent) {
  //console.log(filteredLayer)
  layer.setVisible(false)
  filteredLayers.getLayers().push(filteredLayer)
  layerSwitcher.renderPanel()
  let mapSize = map.getSize()
  view.fit(extent, {
    size: mapSize, 
    padding: [50, 50, 50, 50],
    duration: 3000,
  });
}

function removeFilterLayer(filteredLayer, layer) {
  //console.log(filteredLayer)
  filteredLayers.getLayers().remove(filteredLayer)
  layerSwitcher.renderPanel()
  layer.setVisible(true)
  view.animate({
    center: current_view_values.currentCenter, 
    zoom: current_view_values.currentZoom,
    duration: 1000,
  });
}
function createFilteredLayer(source, title) {
  landInvLayer_filtered = new VectorLayer({
    title: title,
    style: styleFunction_12, 
    visible: true,
    source: source,
  });
  return landInvLayer_filtered;
}
function createFilteredSource(filteredSource) {
  vectorSourceWFS_filtered = new VectorSource({
    features: new GeoJSON().readFeatures(filteredSource)
  });
  return vectorSourceWFS_filtered;
}

// function to open filter pane
let filter_pane = document.getElementById("filter-pane");
let filter_closer = document.getElementById('filter-closer');
let instruction_button = document.getElementById("instruction-button");
let feature_button = document.getElementById("feature-button");
let filter_button = document.getElementById("filter-button");


instruction_button.addEventListener('click', openInstructionPane);
feature_button.addEventListener('click', openFeaturePane);
filter_button.addEventListener('click', openFilterPane);
//filter_closer.addEventListener('click', closeFilterPane);

function openInstructionPane() {  
  instruction_button.classList.add("active");
  feature_button.classList.remove("active");
  filter_button.classList.remove("active");
  List.style.display = "flex";
  FeatureList.style.display = "none";
  filter_pane.style.display = "none"; 
}
function openFeaturePane() {  
  feature_button.classList.add("active");
  instruction_button.classList.remove("active");
  filter_button.classList.remove("active");
  FeatureList.style.display = "block";
  List.style.display = "none";
  filter_pane.style.display = "none"; 
}
function openFilterPane() {  
  filter_button.classList.add("active");
  instruction_button.classList.remove("active");
  feature_button.classList.remove("active");
  FeatureList.style.display = "none";
  List.style.display = "none";
  filter_pane.style.display = "block"; 
  let viewportWidth = window.innerWidth;
  };

/* ********** END FILTER FUNCTIONS ********** */

/* *** begin feature functions *** */

const feature_pid = document.getElementById('feature-pid');

// double click event listener for features
map.on('dblclick', (evt) => {
  evt.preventDefault();
  openFeaturePane();
  let feature = map.forEachFeatureAtPixel(evt.pixel,
    function(feature) {
      console.log(feature)
      return feature;
    });
  createFeature(feature);
  addHighlight(feature);
});  
function addHighlight(feature) {
  //clear popups
  overlay.setPosition(undefined);
  map.removeLayer(landInvLayer_select);
  //Select Feature Layer
  let selectFeature = feature;
  //console.log(selectFeature)
  let vectorSource = new VectorSource({
    features: [selectFeature]
  });
  let geometry = feature.getGeometry();
  let extent = geometry.getExtent()
  console.log(extent)
  
  landInvLayer_select = new VectorLayer({
    //title: 'Select',
    style: styleSelect,
    visible: true,
    opacity: 1,
    source: vectorSource,
  });      
  
  map.addLayer(landInvLayer_select);
}

let attributes = document.getElementById('attributes');
feature_pid.innerText = 'Feature';
  attributes.innerHTML = 'Select a feature by double clicking a parcel on the map or entering the PID or address of a parcel of interest in the search bar.';

/* ****function to create and populate parcel feature**** */
function createFeature(feature) {
  let area_acres = feature.get('area_acres');
  let rounded_area_acres = area_acres.toFixed(4);
  let avg_slope = feature.get('avg_slope');
  let rounded_avg_slope = avg_slope.toFixed(2);
  let dev_score_basic = feature.get('development_score_basic');
  let dev_score_weighted = feature.get('development_score_weighted');
  let dev_score_services = feature.get('development_score_service');
  let services_score_sum = feature.get('services_score_sum');
  let dev_score_slope = feature.get('development_score_slope_weighted');
  let zone_priority = feature.get('zone_priority');
  let current_use = feature.get('current_use');
  let avg_slope_score = feature.get('avg_slope_score');
  let service = feature.get('services');
  let services = (service === 'Y') ? 'Yes' : 'No';
  let water_service = feature.get('water_service');
  let water_services = (water_service === 'Y') ? 'Yes' : 'No';
  let electric_service = feature.get('electric_service');
  let electric_services = (electric_service === 'Y') ? 'Yes' : 'No';
  let sanitary_service = feature.get('sanitary_service');
  let sanitary_services = (sanitary_service === 'Y') ? 'Yes' : 'No';
  let natural_gas_service = feature.get('natural_gas_service');
  let natural_gas_services = (natural_gas_service === 'Y') ? 'Yes' : 'No';
  let connectivity = feature.get('connectivity');
  let connectivity_1 = (connectivity === 'Y') ? 'Yes' : 'No';
  let ms_building = feature.get('ms_building');
  let ms_buildings = (ms_building === 'Y') ? 'Yes' : 'No';
  let flood_risk = feature.get('flood_risk')
  let flood_risks = (flood_risk === 'Y') ? 'Yes' : 'No';
  let environmental_remediation = feature.get('environmental_remediation')
  let environmental_remediations = (environmental_remediation === 'Y') ? 'Yes' : 'No';

  feature_pid.innerText = `PID - ${feature.get('pid')}`
  let featureTable = `
    <table id=feature-table>
      <tr>
        <th>ZONE</th>
        <td>${feature.get('zone_name')}</td>
      </tr>
      <tr>
        <th>AREA (acres)</th>
        <td>${rounded_area_acres}</td>
      </tr>
      <tr>
        <th>AVERAGE SLOPE</th>
        <td>${rounded_avg_slope}</td>
      </tr>
      <tr>
        <th>ZONE NAME (abrv)</th>
        <td>${feature.get('zone_name_short')}</td>
      </tr>
      <tr>
        <th>ZONE ADMINISTRATION</th>
        <td>${feature.get('zone_admin')}</td>
      </tr>
      <tr>
        <th>ADDRESS</th>
        <td>${feature.get('full_addr')}</td>
      </tr>
      <tr>
        <th>DEVELOPMENT SCORE (Basic)</th>
        <td>${dev_score_basic}
          <div class="swatch" style="background-color:rgb(${getColorByValue(fillColors_9, dev_score_basic)})"></div>
        </td>
      </tr>
      <tr>
      <th>DEVELOPMENT SCORE (Weighted)</th>
      <td>${dev_score_weighted}
        <div class="swatch" style="background-color:rgb(${getColorByValue(fillColors_12, dev_score_weighted)})"></div>
      </td>
      </tr>
      <tr>
        <th>DEVELOPMENT SCORE (Weighted - Service)</th>
        <td>${dev_score_services}
          <div class="swatch" style="background-color:rgb(${getColorByValue(fillColors_10, dev_score_services)})"></div>
        </td>
      </tr>
      <tr>
        <th>DEVELOPMENT SCORE (Weighted - Slope)</th>
        <td>${dev_score_slope}
          <div class="swatch" style="background-color:rgb(${getColorByValue(fillColors_13, dev_score_slope)})"></div>
        </td>
      </tr>
      <tr>
        <th>SERVICES</th>
        <td>${services}</td>
      </tr>
      <tr>
        <th>SERVICES SCORE</th>
        <td>${services_score_sum}
          <div class="swatch" style="background-color:rgb(${getColorByValue(fillColors_6, services_score_sum)})"></div>
        </td>
      </tr>
      <tr>
        <th>ZONE PRIORITY SCORE</th>
        <td>${zone_priority}
          <div class="swatch" style="background-color:rgb(${getColorByValue(fillColors_5, zone_priority)})"></div>
        </td>
      </tr>
      <tr>
        <th>CURRENT USAGE</th>
        <td>${current_use}
          <div class="swatch" style="background-color:rgb(${getColorByValue(fillColors_6, current_use)})"></div>
        </td>
      </tr>
      <tr>
        <th>AVERAGE SLOPE SCORE</th>
        <td>${avg_slope_score}
          <div class="swatch" style="background-color:rgb(${getColorByValue(fillColors_5, avg_slope_score)})"></div>
        </td>
      </tr>
      <tr>
        <th>BUILDING PRESENT (Microsoft data)</th>
        <td>${ms_buildings}</td>
      </tr>
      <tr>
        <th>FLOOD RISK</th>
        <td>${flood_risks}</td>
      </tr>
      <tr>
        <th>ENVIRONMENTAL REMEDIATION</th>
        <td>${environmental_remediations}</td>
      </tr>
    </table>  
  `;
  attributes.innerHTML = featureTable;
}

function getColorByValue(fillColors, value) {
  const matchingColor = fillColors.find(color => color.value === value);
  return matchingColor ? matchingColor.color : '255, 255, 255';
}

function clearFeature() {
  openInstructionPane();
  let attributes = document.getElementById('attributes');
  feature_pid.innerHTML = 'Feature';
  attributes.innerHTML = 'Select a feature by double clicking a parcel on the map or entering the PID of a parcel of interest in the search bar.';
  
  map.removeLayer(landInvLayer_select);
}
// slides left panel closed or toggles panel open
function hideList() {
    toggleImage(list_toggle_img, panel_close, panel_open);
    // Update container size, timeout must match or exceed css transition for list pane closing
    setTimeout(function () {
      map.updateSize();
    }, 500)
    wrapper.classList.toggle('no-list');
};
/* *** end feature functions *** */

/* *** begin data pane functions *** */
// toggles data sections open/closed
  const toggleArrows = document.getElementsByClassName('toggle-arrow');

  Array.from(toggleArrows).forEach(arrow => {
    arrow.addEventListener('click', () => {
      const contentId = arrow.dataset.contentId;
      console.log(contentId)
      const content = document.getElementById(contentId);
      content.classList.toggle('hidden-content');

      if (content.classList.contains('hidden-content')) {
        arrow.src = 'https://trugis.ca/wp-content/uploads/2023/04/closed_drkBlu.png';
      } else {
        arrow.src = 'https://trugis.ca/wp-content/uploads/2023/04/opened_drkBlu.png';
      }
    });
  });

  // toggles zoning table
    const toggleZoningTable = document.getElementById("toggle-zoning-table");
    const popupWindow = document.getElementById("popup-window");
    const closePopup = document.getElementById("close-popup");
    const zonesIframe = document.getElementById("zones-iframe");
  
    toggleZoningTable.addEventListener("click", () => {
      popupWindow.classList.remove("hidden");
      zonesIframe.setAttribute('srcdoc', zoneTable);
    });
  
    closePopup.addEventListener("click", () => {
      popupWindow.classList.add("hidden");
      zonesIframe.setAttribute('srcdoc', '');
    });

//const popupContent = document.getElementById('zones-iframe');
const zoneTable = 
`<table>
    <tr>
        <td>Municipality</td>
        <td>Zoning Short</td>
        <td>Zoning Long</td>
        <td>Zoning Description</td>
        <td>Zoning Priority</td>
    </tr>
    <tr>
        <td>Fruitvale</td>
        <td>C2</td>
        <td>WFLD - General Commercial/Dev Permit Area</td>
        <td>General Commercial (C2) is intended to accommodate commercial developments including service, business, retail and administration uses in central business areas.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Fruitvale</td>
        <td>I1</td>
        <td>FRVL - General Industrial</td>
        <td>General Industrial</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Fruitvale</td>
        <td>C1</td>
        <td>FRVL - Village Centre Commercial</td>
        <td>General Commercial</td>
        <td>2</td>
    </tr>
    <tr>
        <td>Montrose</td>
        <td>C1</td>
        <td>MTRS - Commercial 1</td>
        <td>General commercial</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Montrose</td>
        <td>C2</td>
        <td>MTRS - Commercial 2</td>
        <td>General commercial plus Warehousing and wholesale distribution.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>C</td>
        <td>B - Commercial</td>
        <td>General commercial including restaurants, hotel, motel, office, passenger terminal, retail, service station.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>C1</td>
        <td>A - Commercial 1</td>
        <td>General commercial use including auto repair, restaurants, funeral home, post office motel, office retail vetrinary clinic, etc.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>C2</td>
        <td>A - Commercial 2</td>
        <td>Commercial 1 plus construction contractors office</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN1</td>
        <td>A - Industrial 1</td>
        <td>Automobile and truck cleaning facility;  Bulk m aterial storage;  Concrete and asphalt plants;  Distribution facility;  Freight terminal;  Gravel processing including rock-crushing;  Manufacturing of log homes;  Marshalling yard; Passenger terminal;  Railway siding; Storage; Vehicle and heavy equipment maintenance and repair;</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN1</td>
        <td>B - Industrial 1</td>
        <td>Building and contracting supply establishment; Contractor’s shops and yards; Distribution facility; Eating and drinking establishment; Freight terminal; Light manufacturing; Passenger terminal; Rental, sales and associated service facilities for vehicles and light equipment; Storage;</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN2</td>
        <td>A - Industrial 2</td>
        <td>Industrial 1 plus Agriculture including intensive agriculture; Automobile salvage yard; Bulk fuel depot; Bulk material storage; Distribution facility; Light m anufacturing; Manufacturing; Metal smelting and refining operation; Recycling depot; Vehicle and heavy equipment maintenance and repair; etc.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN2</td>
        <td>B - Industrial 2</td>
        <td>Industrial 1 plus Log home manufacturing; Veterinary clinic;</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN3</td>
        <td>A - Industrial 3</td>
        <td>Automobile and truck cleaning facility; Building or contracting supply establishment;  Contractor’s shop and yard; Distribution facility;  Freight terminal;  Light m anufacturing; Railway siding; Storage (indoor); Vehicle and heavy equipment maintenance and repair;</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN3</td>
        <td>B - Industrial 3</td>
        <td>Industrial 1 plus Animal shelters; Automobile salvage yard; Building and contracting supply establishment; Bulk fuel depot; Cannabis Cultivation; Contractor’s shops and yards; Distribution facility; Recycling depot; Recycling facility; Vehicle and heavy equipment maintenance and repair;</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN4</td>
        <td>A - Industrial 4</td>
        <td>Agriculture; Building or contracting supply establishment;  Contractor’s shop and yard; Light m anufacturing; Manufacturing of log homes; Storage, indoor; Veterinary clinic. Storage, outdoor.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN4</td>
        <td>B - Industrial 5</td>
        <td>Bulk material storage; Distribution facility; Freight terminal; Manufacturing; Passenger terminal; Railway siding; Vehicle and heavy equipment maintenance and repair; Storage; Wholesale establishment</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN5</td>
        <td>A - Industrial 5</td>
        <td>Manufacturing; Storage.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN5</td>
        <td>B - Industrial 6</td>
        <td>Restaurants, Freight terminal; Post Office; Roadside retail stand for sale of vegetable/fruit produce or crafts, not to exceed 10m2 in area; Service station, provided the service component is limited to one indoor service bay of a maximum area of 95 m2 for passenger (non-commercial) vehicle storage or repair only; outdoor automobile or truck repair is prohibited; Truck and trailer storage facility.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>IN6</td>
        <td>A - Industrial 6</td>
        <td>Asphalt plant, Automobile salvage yard; Light manufacturing; Recycling facility; Storage; Vehicle and heavy equipment maintenance and repair.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>C2</td>
        <td>B - Commercial 2</td>
        <td>Resource use, Retreat campground.</td>
        <td>2</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>AGR1</td>
        <td>A -Agricultural Resource 1</td>
        <td>Agriculture; Forestry and logging, excluding manufacturing except portable wood manufacturing plants;  intensive agriculture; K ennel, provided that all animals are kept within a wholly enclosed and soundproofed building; Processing and packing of produce grown on the same parcel; Sales of produce grown on the same parcel provided that the area used for display and sales does not exceed 100 m2 ;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>AGR1</td>
        <td>B - Agricultural Resource 1</td>
        <td>Agriculture; Cannabis Cultivation; Intensive Agriculture; </td>
        <td>1</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>AGR2</td>
        <td>A -Agricultural Resource 2</td>
        <td>Agriculture; Forestry and logging, excluding manufacturing except portable wood manufacturing plants;  intensive agriculture; K ennel, provided that all animals are kept within a wholly enclosed and soundproofed building; Processing and packing of produce grown on the same parcel; Sales of produce grown on the same parcel provided that the area used for display and sales does not exceed 100 m2 ;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>AGR2</td>
        <td>B - Agricultural Resource 2</td>
        <td>Agriculture; Cannabis Cultivation; Intensive Agriculture; Campground;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>AGR3</td>
        <td>A -Agricultural Resource 3</td>
        <td>Agriculture; Driving range; Golf course; Pitch and putt course; Rodeo or gymkhana grounds; Accessory buildings and structures; Clubhouses, restaurants, pro shops and similar facilities necessary for golf purposes;  Processing and packing of produce grown on the same parcel; Sales of produce grown on the same parcel provided that the area used for display and sales does not exceed 100 m2 ;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>AGR3</td>
        <td>B - Agricultural Resource 3</td>
        <td>Agriculture; Campground; Driving range; Golf Course; Accessory buildings and structures; </td>
        <td>1</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>CD</td>
        <td>B - Comprehensive Development</td>
        <td>Forestry, logging, silviculture; Agriculture; Equestrian facilities; Outdoor recreation facilities; Common storage and maintenance facility.</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>FR</td>
        <td>A -Forest Resource</td>
        <td>Agriculture; Forest management activity; provided the parcel is classified as managed forest land under the Assessment Act; and Hydroelectric dam. Accessory buildings and structures.</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Regional District of Kootenay Boundary</td>
        <td>FR</td>
        <td>B - Forest Resource</td>
        <td>Agriculture; Forest management activity, provided the parcel is classified as managed forest land under the Assessment Act; </td>
        <td>1</td>
    </tr>
    <tr>
        <td>Rossland</td>
        <td>M1</td>
        <td>Light Industrial</td>
        <td>The intent of this zone is to provide an area for economic activities which demand larger spaces, and involve primary processing and storage. Automobile wrecking yard; Bulk fuel depot; Cartage operation; Food and beverage processing establishment; Light equipment sales and rental establishment; Light manufacturing, processing and assembly; Public utility use; Warehouse, shipping, wholesale and rental storage facilities; Micro Processing, Cannabis; Standard Processing, Cannabis; Commercial, Service Use.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Rossland</td>
        <td>C2</td>
        <td>Commercial Service</td>
        <td>The intent of this Zone is to allow commercial uses to provide a variety of non-household and non-personal goods and services. Service Commercial Use, Entertainment Commercial Use, Recreation Facilities, Neighbourhood Oriented Commercial Use, Hotel, Motel, Hostel, Apartment Dwelling(s) in conjunction with a principal commercial use</td>
        <td>2</td>
    </tr>
    <tr>
        <td>Rossland</td>
        <td>C1</td>
        <td>Commercial Downtown Core</td>
        <td>The intent of this Zone is to allow for commercial uses to provide personal goods, services and entertainment, along with residential uses, within the Downtown Core Area. Downtown Commercial Use, Entertainment Commercial Use, Neighbourhood Oriented Commercial Use, Short-Term Rental, Hotel, Hostel, Farmers Market, Cannabis Retail Store, Apartment Dwelling(s) in conjunction with a principal commercial use</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Rossland</td>
        <td>C3</td>
        <td>Commercial Resort Accommodation/Commercial</td>
        <td>The intent of this zone is to allow for a mix of multiple family residential and commercial uses adjacent to the resort areas. Short-Term Rental, Hotel, Hostel, Recreational Facilities, Personal Service Establishment, Resort Commercial Use.</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Rossland</td>
        <td>CD1 - AVC1</td>
        <td>Comprehensive Development Zone 1 - Alpine Village Core</td>
        <td>The purpose of this zone is to provide for the development of a variety of land uses integrated into a planned resort village. The Comprehensive Development One Zone is divided into ten (10) separate Sub Zones. Each sub-zone in the Comprehensive Development One Zone has regulations pertaining to the sub-zone. Short-Term Rental, Hotel, Hostel, Resort Commercial Use; Eating and Drinking Establishments; Personal Service Establishment; Real estate office; Skier Services; Public Assembly; Recreation facilities; Day-care facilities;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Rossland</td>
        <td>CD1 - AVC2</td>
        <td>Comprehensive Development Zone 1 - Lower Alpine Village Core &#39;A&#39;</td>
        <td>The purpose of this zone is to provide for the development of a variety of land uses integrated into a planned resort village. The Comprehensive Development One Zone is divided into ten (10) separate Sub Zones. Each sub-zone in the Comprehensive Development One Zone has regulations pertaining to the sub-zone. Recreation Facilities; Hostel; Short-Term Rental;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Rossland</td>
        <td>CD1 - RLI1</td>
        <td>Comprehensive Development Zone 1 - Resort Light Industrial</td>
        <td>The purpose of this zone is to provide for the development of a variety of land uses integrated into a planned resort village. The Comprehensive Development One Zone is divided into ten (10) separate Sub Zones. Each sub-zone in the Comprehensive Development One Zone has regulations pertaining to the sub-zone. Equipment maintenance; Storage facilities; Accessory Buildings and Structures.</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Rossland</td>
        <td>CD4</td>
        <td>Comprehensive Development Zone 4 - Multiple Family/Limited Commercial</td>
        <td>The purpose of this zone is to provide for the development of multiple family dwellings and limited Commercial space in the old hospital building at 1961 Georgia St. Clinic, Office, Personal Service Establishment, Ambulance Services; Parking area; Accessory Buildings. </td>
        <td>1</td>
    </tr>
    <tr>
        <td>Rossland</td>
        <td>P3</td>
        <td>Resort Recreation</td>
        <td>The intent of this zone is to provide areas to support resort recreational uses. Development should be limited to facilities required to support the use and maintenance of the recreation areas. Campground; Golf Course; Leisure Facilities; Ski Facilities; Recreation Facilities;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C1</td>
        <td>General Commercial</td>
        <td>General Commercial Zone</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C1A</td>
        <td>General Commercial Zone</td>
        <td>&quot;General commercial + warehousing and storage facilities with a gross floor area of not more than 929m2 (10,000 ft.2).&quot;</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C10</td>
        <td>Business Enterprise Zone</td>
        <td>business or enterprise centers provided that no business, use or occupation locating in such center shall produce any offensive noise, vibration, smoke, dust, odor, glare or electrical interference;</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C10A</td>
        <td>Business Enterprise Zone</td>
        <td>an eating and drinking establishment, excluding drive-in food services; a gymnasium, reducing salon, health spa or similar athletic establishment; a childcare centre; business, government and professional offices; storage facilities; and residential use when combined with the above to a maximum of 13 dwelling units.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C5</td>
        <td>Highway Commercial Zone</td>
        <td>Similar to General Commercial minus banks, but with additional focus on automobile and recreational vehicle sales, clothing manufacturing etc.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C6</td>
        <td>Special Highway Commercial Zone</td>
        <td>Esentially the same as General Commercial minus banks, but with the addition of contractors offices, recyclig services, and warehousing not more than 5000 ftsq.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>CDC5</td>
        <td>Comprehensive Development District - Highway Commercial Zone</td>
        <td>These Districts provide for the development of an area embracing one or more land use classifications as an integrated unit based upon a comprehensive development plan. - Similar to General Commercial minus banks, but with additional focus on automobile and recreational vehicle sales, clothing manufacturing etc.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>M1</td>
        <td>Light Industrial Zone</td>
        <td>&quot;Similar to General Commercial with the addition of small equipment/machinery, parts, and tools - manufacturing, repairs, sales, rental, and storage; welding shops; food, beverage and dairy products manufacturing and processing, excluding breweries; warehousing, moving, terminal, and storage facilities, excluding wrecking and junk yards; manufacturing, repair, and wholesale of electrical and electronic products; public works, vehicle storage, and equipment storage yards; etc&quot;</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>M2</td>
        <td>Heavy Industrial Zone</td>
        <td>concrete and asphalt plants and products; manufacturing, refining, storage, and wholesale of petroleum and chemical products; foundries and metal fabrication industries; gravel and sand pits, and mineral extractive industries, including storage of such materials; manufacturing, processing and storage of wood and lumber products; manufacturing, processing, and assembly industries; electric power generation and distribution works and facilities; garbage incineration and reducing plants and solid waste disposal site; abattoirs; septic tank services; etc.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C2</td>
        <td>Major Neighborhood Commercial Zone</td>
        <td>General Commercial minus - medical and dental clinics and similar establishments; banks and financial institutions, insurance and security brokers, and real estate offices; child care centres; rest homes and senior citizens housing; universities, colleges, commercial schools, and studios; hotels, banquet halls, and cabarets; radio and television studios; passenger bus depots and taxi offices; rooming units; off-street parking structures; building supplies, sign painting and fabrication, and similar establishments;</td>
        <td>2</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C3</td>
        <td>Neighborhood Commercial Zone</td>
        <td>Limited to a variety of small retail and repair such as laundry, grocery, pharmacy, restaurants, and apartments.</td>
        <td>2</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C4</td>
        <td>Shopping Centre Commercial Zone</td>
        <td>Can contain various retail including child care, mail order, gas stations, banks, offices, churches, and cannabis retailers</td>
        <td>2</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C7</td>
        <td>Service Commercial Zone </td>
        <td>Various services such as automobile service, dealership etc, contractors offices, laboratories, food and other manufacturing</td>
        <td>2</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C8</td>
        <td>Tourist Commercial Zone</td>
        <td>Campgrounds, motels, drive-in theaters, parks, playgrounds grocery, restaurant etc.</td>
        <td>2</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>A1</td>
        <td>Rural Holding Zone</td>
        <td>Agricultural uses</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>A2</td>
        <td>Rural Holding Zone</td>
        <td>agricultural uses; forestry, logging and silviculture; public utility transmission lines;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C3A</td>
        <td>Neighborhood Commercial Zone</td>
        <td>&quot;permit an eating and drinking establishment to be developed behind the single family dwelling&quot;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C4A</td>
        <td>Shopping Centre Commercial Zone</td>
        <td>Limited to retail stores greater than 4600 m2 (49,515 sq. ft.); eating and drinking establishments, including drive-in food service; accessory automotive services; accessory uses, buildings and structures.</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C6A</td>
        <td>Special Highway Commercial Zone</td>
        <td>apartments combined with other permitted uses.</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C7A</td>
        <td>Service Commercial Zone </td>
        <td>welding shops.</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C7B</td>
        <td>Service Commercial Zone </td>
        <td>&quot;one dwelling unit which is accessory to the permitted uses and located above the ground floor of the building.&quot;</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C7C</td>
        <td>Service Commercial Zone </td>
        <td>an eating establishment, excluding drive-in food services.</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Trail</td>
        <td>C9</td>
        <td>Business Commercial Zone</td>
        <td>Limited to business, government and professional offices; one dwelling unit which is accessory to the permitted uses and located within or behind the principal building; and accessory uses, buildings and structures.</td>
        <td>1</td>
    </tr>
    <tr>
        <td>Warfield</td>
        <td>C1</td>
        <td>WFLD - Comprehensive Commercial/Dev Permit Area</td>
        <td>Comprehensive Commercial (C1) is intended to accommodate commercial developments that require larger sites, or sites that serve the motoring public and require direct access to a highway.</td>
        <td>3</td>
    </tr>
    <tr>
        <td>Warfield</td>
        <td>C2</td>
        <td>FRVL - Highway Commercial</td>
        <td>General Commercial (C2) is intended to accommodate commercial developments including service, business, retail and administration uses in central business areas.</td>
        <td>2</td>
    </tr>
    <tr>
        <td>Warfield</td>
        <td>CD1</td>
        <td>WFLD - Comprehensive Development 1</td>
        <td>the proposed development is, in the opinion of Council, considered appropriate for the site, having regard for the policies and objectives of the Official Community Plan and any other applicable municipal plan or policy;</td>
        <td>2</td>
    </tr>
</table>
<style>
table {
  width: 100%;
  border-collapse: collapse;
  }

table th,
  table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  }

table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
  }
</style>`;


//popupContent.innerHTML = zoneTable;
/* *** end data pane functions*** */

// function to toggle image
function toggleImage(element, img_1, img_2) {
  if (wrapper.classList.contains('no-list')) {
    element.src = img_1;
  } else {
    element.src = img_2;
  }
}

// function to get current mapview values
function getValues() {
  let currentCenter = view.getCenter();
  //console.log(currentCenter);
  let currentZoom = view.getZoom();
  //console.log(currentZoom);
  let viewValues = {
    currentCenter: currentCenter,
    currentZoom: currentZoom
  }
  // console.log(viewValues);
  return viewValues;
};

/* *** begin splash-screen button and pane *** */
const splash_screen = document.getElementById("splash-screen");
const splash_button = document.getElementById("splash-button");
const splash_closer = document.getElementById("splash-closer");

splash_button.addEventListener("click", function() {  
  if (splash_screen.style.display === "block") {
    splash_screen.style.display = "none";
  } else {
    splash_screen.style.display = "block";
  }
});
splash_closer.addEventListener("click", function() {  
  if (splash_screen.style.display === "none") {
    splash_screen.style.display = "block";
  } else {
    splash_screen.style.display = "none";
  }
});
/* *** end splash-screen button and pane *** */

/* *** Begin legend button and pane *** */
const legend_wrapper = document.getElementById("legend-wrapper");
const legend_button = document.getElementById("legend-button");
const legend_closer = document.getElementById("legend-closer");
const fontName = 'Comfortaa';
const fontColor = '8e8d8d';
const fontSize = '7';
const fontStyle = 'bold';
const bgColor = 'FFFFFF';

const landInv_HighLow = [
  {
    'layer': 'Land Inventory Development Potential (Basic)',
    'valueHigh': '15',
    'valueLow': '7',
  },
  {
    'layer': 'Land Inventory Development Potential (Weighted)',
    'valueHigh': '19',
    'valueLow': '8',
  },
  {
    'layer': 'Land Inventory Industrial/Commercial Select (Basic)',
    'valueHigh': '15',
    'valueLow': '7',
  },
  {
    'layer': 'Land Inventory Development Potential (Services Weighted)',
    'valueHigh': '16',
    'valueLow': '7',
  },
  {
    'layer': 'Land Inventory Development Potential (Slope Weighted)',
    'valueHigh': '20',
    'valueLow': '8',
  },
  {
    'layer': 'Land Inventory Current Use',
    'valueHigh': '4',
    'valueLow': '0',
  },
  {
    'layer': 'Land Inventory Services',
    'valueHigh': '5',
    'valueLow': '0',
  },
  {
    'layer': 'Land Inventory Average Slope',
    'valueHigh': '4',
    'valueLow': '0',
  },
  {
    'layer': 'Land Inventory Zoning Priority',
    'valueHigh': '3',
    'valueLow': '1',
  },
]

legend_button.addEventListener("click", function() {  
  if (legend_wrapper.style.display === "none") {
    legend_wrapper.style.display = "block";
  } else {
    legend_wrapper.style.display = "none";
  }
});
legend_closer.addEventListener("click", function() {  
    legend_wrapper.style.display = "none";
});
// let getLegendWMS = `${geoServerDomain}wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=10&HEIGHT=10&LAYER=${nameSpace}:${layerName}&legend_options=fontName:${fontName};fontAntiAliasing:true;fontColor:0x${fontColor};fontSize:${fontSize};fontStyle:${fontStyle};bgColor:0x${bgColor};dpi:180;`;
//let getLegendWMS = `https://lcic.ca/wp-content/uploads/2023/03/legend_ramp_lrg.png`;
//img.src = getLegendWMS;
//legend.appendChild(img);

// function legend() {
//   let legend = document.getElementById("legend");
//   let table = document.getElementById("legend-table");
//   let tr = document.createElement("tr");
//   let th = document.createElement("th");
//   let td = document.createElement("td");
//   const buildingWMS_legendURL = tileSourceWMS_msBuildings.getLegendUrl();
//   var img = new Image();
  
//   img.src = buildingWMS_legendURL;
//   th.appendChild(img);
//   tr.appendChild(th);
//   td.appendChild(document.createTextNode("Buildings"));
//   tr.appendChild(td);
//   table.appendChild(tr);
  
// }
// legend();
// Add event listener to update legend when visibility changes
inventoryLayers.getLayers().forEach(layer => {
  layer.on('change:visible', () => {
    legend();
  });
});
assetLayers.getLayers().forEach(layer => {
  layer.on('change:visible', () => {
    legend();
  });
});
adminLayers.getLayers().forEach(layer => {
  layer.on('change:visible', () => {
    legend();
  });
});

function legend() {
  const activeLayers = assetLayers.getLayers().getArray().filter(layer => layer.getVisible());
  const activeLayers_admin = adminLayers.getLayers().getArray().filter(layer => layer.getVisible());
  const activeLayers_inv = inventoryLayers.getLayers().getArray().filter(layer => layer.getVisible());
  let activeLayer_inv = inventoryLayers.getLayers().getArray().filter(function(layer) {
    return layer.getVisible();
    })[0];
  const activeLayer_invTitle = activeLayer_inv.get('title')
  console.log(activeLayer_invTitle);

  let legend = document.getElementById("legend");
  //let table = document.getElementById("legend-table");
  const table1 = document.createElement("table");
  const table2 = document.createElement("table");
  const table3 = document.createElement("table");

  // Clear the legend table
  legend.innerHTML = "";
  const tr = document.createElement("tr");
  const th = document.createElement("th");
  const td = document.createElement("td");
  const td2 = document.createElement("td");
  const img = new Image();
  const h5  = document.createElement("h5");
  table2.setAttribute("id", "legend-table-2");
  table2.classList.add("legend-table");

  img.src = "https://lcic.ca/wp-content/uploads/2023/03/legend_ramp_lrg-e1678824749329.png";
  img.setAttribute("width", "20px");
  img.setAttribute("height", "120px");
  th.appendChild(img);
  tr.appendChild(th);
 
  let valueHigh, valueLow;
  for (let i = 0; i < landInv_HighLow.length; i++) {
    if (landInv_HighLow[i].layer === activeLayer_invTitle) {
      valueHigh = landInv_HighLow[i].valueHigh;
      valueLow = landInv_HighLow[i].valueLow;
      break;
    }
  }  
  h5.classList.add("legend-group-title");
  h5.appendChild(document.createTextNode(activeLayer_invTitle));
  td.appendChild(document.createTextNode(`High Potential (${valueHigh})`));
  td2.appendChild(document.createTextNode(`Low Potential (${valueLow})`));
  td2.setAttribute("id", "low-dev-element");
  legend.appendChild(h5);
  tr.appendChild(td);
  tr.appendChild(td2);
  table2.appendChild(tr);
  legend.appendChild(table2);

  if (activeLayers.length > 0) {
    const h5 = document.createElement("h5");
    table1.setAttribute("id", "legend-table-1");
    table1.classList.add("legend-table");
    h5.classList.add("legend-group-title");
    h5.appendChild(document.createTextNode("Raw Asset Layers"));
    legend.appendChild(h5);
    legend.appendChild(table1);

    // Loop through activeLayers array in reverse order
    for (let i = activeLayers.length - 1; i >= 0; i--) {
      const layer = activeLayers[i];
      let tr = document.createElement("tr");
      let th = document.createElement("th");
      let td = document.createElement("td");
      td.classList.add("legend-layer");
      th.classList.add("legend-layer-swatch");
      const legendURL = layer.getSource().getLegendUrl();
      const img = new Image();
      img.src = legendURL;

      th.appendChild(img);
      tr.appendChild(th);
      td.appendChild(document.createTextNode(layer.get("title")));
      tr.appendChild(td);
      table1.appendChild(tr);
    }
  };
  
  if (activeLayers_admin.length > 0) {
    const h5  = document.createElement("h5");
    table3.setAttribute("id", "legend-table-3");
    table3.classList.add("legend-table");
    h5.classList.add("legend-group-title");
    h5.appendChild(document.createTextNode("Administrative Layers"));
    legend.appendChild(h5);
    legend.appendChild(table3);

    activeLayers_admin.forEach(layer => {
      let tr = document.createElement("tr");
      let th = document.createElement("th");
      let td = document.createElement("td");
      let div = document.createElement("div");
      const h5  = document.createElement("h5");
      th.classList.add("aoi-layer-swatch");
      td.classList.add("legend-layer");
      const legendURL = layer.getSource().getLegendUrl();
      const img = new Image();
      img.src = legendURL;
      if (layer.get("title") === "LCIC Area of Interest") {
        th.appendChild(img);
        tr.appendChild(th);
        td.appendChild(document.createTextNode(layer.get("title")));
        tr.appendChild(td);
        table3.appendChild(tr);
      } else {
        h5.classList.add("legend-layer-title");
        h5.appendChild(document.createTextNode("Census Subdivisions"));
        div.appendChild(h5)
        div.appendChild(img);
        legend.appendChild(div);
      }
    });
  }
}
legend();


/* *** End Legend button and pane *** */

// /* *** Begin Slider *** */
let activeLayer = landInvLayerWFS;
let activeLayerOpacity = 0.8;
const layers = inventoryLayers.getLayers().getArray();
const filter_Layers = filteredLayers.getLayers().getArray();

filteredLayers.on('change', function() {
  updateSlider(filter_Layers);
});

inventoryLayers.on('change', function() {
  updateSlider(layers);
});

let slider = document.getElementById('slider');
slider.min = 0
slider.max = 100
slider.value = activeLayerOpacity * 100
slider.step = 1

slider.addEventListener('input',function (event) {
  activeLayer.setOpacity(event.target.value / 100)
});

function updateSlider(layerGroup) {
  layerGroup.forEach(function(layer) {
    if (layer.getVisible()) {
      activeLayer = layer;
      activeLayerOpacity = activeLayer.getOpacity();
      slider.value = activeLayerOpacity * 100
    }
  });
  console.log('Active layer changed to: ' + activeLayer.get('title'));
}
/* *** End Slider *** */

/* *** Begin fuzzy search functions *** */
const fuzzySearchBrowsersList = fuzzySearch(geojsonObjWFS.features, [
  {
    name: 'properties.pid',
    weight: 1  
  }, 
  // {
  //   name: 'properties.zone_name',
  //   weight: 0.8
  // }, 
  // {
  //   name: 'properties.zone_admin',
  //   weight: 0.8
  // }, 
  // {
  //   name: 'properties.zone_name_short',
  //   weight: 0.8
  // }, 
  {
    name: 'properties.full_addr',
    weight: 0.9
  }, 
  // {
  //   name:'properties.name_alias',
  //   weight: 0.6
  // }, 
  // {
  //   name: 'properties.civic_id',
  //   weight: 0.5
  // },
  // {
  //   name: 'properties.notes',
  //   weight: 0.4
  // },
  // {
  //   name: 'properties.legal_description',
  //   weight: 0.3
  // },
]);
  
/** @type {HTMLInputElement} */
const browserInputElement = document.getElementById(BROWSER_INPUT_ELEMENT_ID);

// Filter the browsers list when the browser input changes
browserInputElement.addEventListener('input', () => {
  const searchKeyword = browserInputElement.value;
  const filteredList = fuzzySearchBrowsersList(searchKeyword);
  const cleanFilteredList = filteredList.slice(0, BROWSER_SUGGESTIONS_MAX_SIZE).map(el => el.item.properties.pid);
  renderInputSuggestions(browserInputElement, cleanFilteredList);
});

/**
 * Renders a dropdown list of suggestions for an input element.
 *
 * @param {HTMLInputElement} inputEl
 * @param {Array<string>} suggestions
 * @returns {void}
 */
const renderInputSuggestions = (inputEl, suggestions) => {
  /**
   * <app-dropdown
   *   [id]="BROWSER_SUGGESTIONS_ELEMENT_ID"
   *   [options]="suggestions"
   *   [connectedTo]="inputEl">
   */

  /** @type {AppDropdownElement} */
  const existingEl = document.getElementById(BROWSER_SUGGESTIONS_ELEMENT_ID);
  if (existingEl) {
    existingEl.options = suggestions;
    existingEl.connectedTo = inputEl;
    return;
  }

  /** @type {AppDropdownElement} */
  const createdEl = document.createElement('app-dropdown');
  createdEl.id = BROWSER_SUGGESTIONS_ELEMENT_ID;
  createdEl.options = suggestions;
  createdEl.connectedTo = inputEl;

  // On click, set the input value to the suggestion
  createdEl.addEventListener('option-select', () => {
    console.log('option-select', createdEl.selected);
    inputEl.value = createdEl.selected;
    createdEl.remove();
    openFeaturePane();
    const result = vectorSourceWFS.getFeatures().filter(function(feature) {
      return feature.get('pid') === createdEl.selected;
    });
    console.log(result[0]);
    
    createFeature(result[0]);
    addHighlight(result[0]);
    //const coords = result[0] ? result[0].geometry.coordinates : undefined;
    current_view_values = getValues();
  
  //   view.animate({
  //           center: coords,
  //           zoom: 17,
  //           duration:2000,
  //           constrainResolution:true
  //         });
  //   console.log(coords);
   });
  document.documentElement.appendChild(createdEl);
};
/* *** End fuzzy search functions *** */






